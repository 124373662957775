import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import Slide from "../components/swiper/Slide";
import logo from "../assets/icons/riceonelogo.svg";
import riceone from "../assets/icons/riceone-only.svg";
import ricebalilogo from "../assets/icons/ricebali-logo.svg";
// import rice1 from "../assets/images/riceone11.jpg";
// import rice2 from "../assets/images/rice2.png";
// import rice3 from "../assets/images/rice3.png";
// import mobile1 from "../assets/images/mobile1.png";
// import mobile2 from "../assets/images/mobile2.png";
// import mobile3 from "../assets/images/mobile3.png";
import { Flex, Text, Image, Button } from "@chakra-ui/react";
import { primaryLight, primaryDark } from "../utils/colors";
import { motion } from "framer-motion";
import { useMediaQuery } from "react-responsive";
import desktop1original from "../assets/images/desktop/1original.jpg";
import desktop1 from "../assets/images/desktop/1.jpg";
import desktop2 from "../assets/images/desktop/2.jpg";
import desktop3 from "../assets/images/desktop/3.jpg";
import desktop4 from "../assets/images/desktop/4.jpg";
import desktop5 from "../assets/images/desktop/5.jpg";
import desktop6 from "../assets/images/desktop/6.jpg";
import desktop7 from "../assets/images/desktop/7.jpg";
import desktop8 from "../assets/images/desktop/8.jpg";
import desktop9 from "../assets/images/desktop/9.jpg";
import mobile1 from "../assets/images/mobile/1.jpg";
import mobile2 from "../assets/images/mobile/2.jpg";
import mobile3 from "../assets/images/mobile/3.jpg";
import mobile4 from "../assets/images/mobile/4.jpg";
import mobile5 from "../assets/images/mobile/5.jpg";
import mobile6 from "../assets/images/mobile/6.jpg";
import mobile7 from "../assets/images/mobile/7.jpg";
import mobile8 from "../assets/images/mobile/8.jpg";
import mobile9 from "../assets/images/mobile/9.jpg";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import ContactModal from "../components/modals/ContactModal";

// animation
const fadeVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 1,
    },
    delay: 0.5,
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 1,
    },
  },
};

const Home = () => {
  // Modal Config
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  // Swiper Config
  const slides = [
    {
      id: 1,
      image: isMobile ? mobile1 : desktop1,
    },
    // {
    //   id: 2,
    //   image: isMobile ? mobile2 : desktop2,
    // },
    {
      id: 3,
      image: isMobile ? mobile3 : desktop3,
    },
    {
      id: 4,
      image: isMobile ? mobile4 : desktop4,
    },
    {
      id: 5,
      image: isMobile ? mobile5 : desktop5,
    },
    {
      id: 6,
      image: isMobile ? mobile6 : desktop6,
    },
    {
      id: 7,
      image: isMobile ? mobile7 : desktop7,
    },
    {
      id: 8,
      image: isMobile ? mobile8 : desktop8,
    },
    {
      id: 9,
      image: isMobile ? mobile9 : desktop9,
    },
  ];

  return (
    <>
      <Helmet>
        <title>RiceBali</title>
        <meta
          name="description"
          content="An experience designed for those who seek originality and comfort. A boutique resort with 12 villas and 24/7 service welcomes owners for leisure, offering expert management for short-term rentals to ensure the best return on your investment."
        />
      </Helmet>

      {/* // MODAL START */}
      <ContactModal isOpen={isOpen} setIsOpen={setIsOpen} isMobile={isMobile} />
      {/* // MODAL END */}

      <Flex w="100%" direction="column">
        {/* <Flex
          position="absolute"
          top="0"
          left="0"
          w="100%"
          h="100%"
          bg="rgba(0,0,0,0.0)"
          zIndex={1}
        /> */}

        {/* <Flex
          position="absolute"
          top="0"
          left="0"
          w="20%"
          h="10%"
          bg="radial-gradient(circle, rgba(0,0,0,0.2) 0%,  rgba(0,0,0,0.0) 30%)"
          zIndex={1}
        /> */}

        <Flex
          position="absolute"
          top="50%"
          left="50%"
          w="60%"
          h="60%"
          bg="radial-gradient(circle, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.0) 50%)"
          zIndex={1}
          transform="translate(-50%, -50%)"
        />
        <Flex
          w="100%"
          h="100%"
          minH="100vh"
          maxH="100%"
          align="center"
          justify="center"
          position="relative"
          zIndex={2}
        >
          <Flex
            align="center"
            position={"absolute"}
            zIndex={2}
            top="2rem"
            left={["initial", "initial", "3rem"]}
            // transform={["translateX(-50%)", "translateX(-50%)", "none"]}
            // w={["100%", "100%", "initial"]}
          >
            {isMobile ? (
              <Link to="/">
                <Image
                  src={ricebalilogo}
                  alt="RiceBali"
                  h={["2rem", "2rem", "2.5rem"]}
                />
              </Link>
            ) : (
              <Link to="/">
                <Image
                  src={logo}
                  alt="RiceBali"
                  h={["2rem", "2rem", "2.5rem"]}
                />
              </Link>
            )}
          </Flex>

          {!isOpen && (
            <Flex
              align="center"
              justify="center"
              direction="column"
              maxW={["100%", "100%", "500px"]}
              as={motion.div}
              variants={fadeVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
            >
              <Image
                src={riceone}
                alt="RiceOne"
                h={["2rem", "2rem", "2.25rem"]}
              />
              <Text
                fontSize={["1rem", "1rem", "1.125rem"]}
                color={primaryLight}
                m="2rem 0 2.5rem"
                textAlign="center"
                fontWeight="500"
                lineHeight="1.6"
                maxW={["80%", "80%", "initial"]}
              >
                An experience designed for those who seek originality and
                comfort. A boutique resort with 12 villas and 24/7 service
                welcomes owners for leisure, offering expert management for
                short-term rentals to ensure the best return on your investment.
              </Text>
              <Button
                size="lg"
                h="3.5rem"
                px="2.5rem"
                variant="outline"
                color={primaryLight}
                border={`1px solid ${primaryLight}`}
                borderRadius="100rem"
                fontSize="md"
                fontWeight="600"
                _hover={{
                  bg: primaryLight,
                  color: primaryDark,
                }}
                onClick={() => setIsOpen(true)}
              >
                Take a look
              </Button>
            </Flex>
          )}
        </Flex>
        <Flex
          w="100%"
          h="100%"
          minH="100vh"
          maxH="100%"
          position="fixed"
          //   pointerEvents="none"
        >
          <Swiper
            spaceBetween={0}
            slidesPerView={1}
            loop={true}
            lazy={true}
            speed={900}
            centeredSlides={true}
            autoplay={{
              delay: 6000,
              disableOnInteraction: false,
            }}
            // pagination={{
            //   clickable: true,
            // }}
            // navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
          >
            {slides.map((slide) => (
              <SwiperSlide key={slide.id}>
                <Slide image={slide.image} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Flex>
      </Flex>
    </>
  );
};

export default Home;
