import React from "react";
// import ReactGA from "react-ga4";
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import Home from "./pages/Home";
import "./index.scss";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";

export const App = () => {
  return (
    <ChakraProvider>
      <Router>
        <>
          <Routes>
            <Route element={<Header />} />
          </Routes>

          <Routes>
            <Route exact path="/" element={<Home />} />
          </Routes>

          <Routes>
            <Route element={<Footer />} />
          </Routes>
        </>
      </Router>
    </ChakraProvider>
  );
};

export default App;
