export const black = "#000000";
export const white = "#ffffff";
export const primaryDark = "#0B0B0B";
export const secondaryDark = "#38302E";
export const primaryLight = "#FFFBF5";
export const secondaryLight = "#ffffff";
export const primaryMid = "#F8F0E5";
export const secondaryMid = "#433E49";

export const borderDark = "#635653";
export const alternativeLight = "#b6ada2";
