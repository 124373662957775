import React from "react";
import { Flex, Image } from "@chakra-ui/react";

const Slide = ({ image }) => {
  return (
    <Flex w="100%" minH="100vh" h="100%">
      <Image src={image} alt="RiceBali" w="100%" h="100%" loading="lazy" />
    </Flex>
  );
};

export default Slide;
