import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Modal,
  ModalContent,
  ModalBody,
  ModalOverlay,
  Button,
  Flex,
  Text,
  Image,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import {
  primaryDark,
  primaryLight,
  alternativeLight,
} from "../../utils/colors";
import bgplaster from "../../assets/images/bgplaster.png";
import riceone from "../../assets/icons/riceone-only.svg";
import FormControlSelect from "../input/FormControlSelect";
import FormControlInput from "../input/FormControlInput";
import emailjs from "@emailjs/browser";

const ContactModal = ({ isOpen, setIsOpen, isMobile }) => {
  //REF
  const form = useRef();

  // NAVIGATION
  const navigate = useNavigate();

  // TOAST
  const toast = useToast();

  //STATE
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [bedrooms, setBedrooms] = useState("any");
  const [bathrooms, setBathrooms] = useState("any");
  const [roiGoal, setRoiGoal] = useState("");

  const [stepSucess, setStepSucess] = useState(false);

  const [sending, setSending] = useState(false);

  const emailjsServiceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
  const emailjsTemplateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
  const emailjsPublicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;

  // VALIDATION

  const isEmailValid = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const isSendDisabled = () => {
    if (name === "" || email === "") {
      return true;
    }
    if (!isEmailValid(email)) {
      return true;
    }
    return false;
  };

  // OPTIONS
  const bedroomsOptions = [
    { value: "any", label: "Any" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
  ];

  const bathroomsOptions = [
    { value: "any", label: "Any" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
  ];

  // HANDLERS
  //   const handleDownloadPDF = async () => {
  //     try {
  //       const response = await fetch(
  //         "https://ricebali.com/ricebali-presentation-2024.pdf",
  //         {
  //           mode: "no-cors", // This mode will avoid CORS errors but make the response opaque
  //         }
  //       );
  //       const blob = await response.blob();
  //       const downloadUrl = window.URL.createObjectURL(blob);
  //       const link = document.createElement("a");
  //       link.href = downloadUrl;
  //       link.setAttribute("download", "ricebali-presentation-2024.pdf"); // Specify the download filename
  //       document.body.appendChild(link);
  //       link.click();
  //       link.parentNode.removeChild(link);

  //       console.log("PDF downloaded successfully.", response);
  //     } catch (error) {
  //       console.error("Error downloading the PDF:", error);
  //       toast({
  //         title: "Failed to download PDF.",
  //         description: "Could not download the PDF file. Please try again later.",
  //         status: "error",
  //         duration: 3000,
  //         isClosable: true,
  //       });
  //     }
  //   };

  const sendEmail = (e) => {
    e.preventDefault();
    setSending(true);

    // Ensure the form reference exists and is correct.
    if (!form.current) {
      toast({
        title: "Error",
        description: "Form not available.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setSending(false);
      return;
    }

    emailjs
      .sendForm(
        emailjsServiceId,
        emailjsTemplateId,
        form.current,
        emailjsPublicKey
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response);
          setStepSucess(true);
          //   closeHandler();
        },
        (error) => {
          console.error("FAILED...", error.text);
          toast({
            title: "Failed to send email.",
            description: `Error: ${error.text}`,
            status: "error",
            duration: 3000,
            isClosable: true,
          });

          //   closeHandler();
        }
      )
      .finally(() => {
        setSending(false);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    sendEmail(event);
  };

  const closeHandler = () => {
    setIsOpen(false);
    setStepSucess(false);
    setName("");
    setEmail("");
    setPhone("");
    setBedrooms("any");
    setBathrooms("any");
    setRoiGoal("");
  };

  // LOGS
  // console.log("name", name);
  // console.log("email", email);
  // console.log("phone", phone);
  // console.log("bedrooms", bedrooms);
  // console.log("bathrooms", bathrooms);
  // console.log("roiGoal", roiGoal);

  // console.log("apiKey", emailjsPublicKey);
  // console.log("serviceId", emailjsServiceId);
  // console.log("templateId", emailjsTemplateId);

  return (
    <>
      {/* // MODAL START */}
      <Modal
        isOpen={isOpen}
        onClose={closeHandler}
        isCentered={isMobile ? false : true}
      >
        <ModalOverlay />
        <ModalContent
          borderRadius="2rem"
          //   overflow="hidden"
          bg="transparent"
          p="0"
          maxW={["90%", "90%", "800px"]}
        >
          <Flex
            bgImage={bgplaster}
            bgSize="cover"
            bgPos="center"
            p="1rem"
            position="absolute"
            left="0"
            top="0"
            width="100%"
            height="100%"
            opacity="0.8"
            borderRadius="2rem"
          />

          <ModalBody position="relative" zIndex={2}>
            {stepSucess && (
              <Flex
                w="100%"
                align="center"
                justify="center"
                direction="column"
                m="2rem 0 0"
              >
                <Image src={riceone} alt="RiceOne" h="1.75rem" />
                <Text
                  fontSize={["1.5rem", "1.5rem", "2rem"]}
                  color={primaryLight}
                  m="2rem 0 0"
                  fontWeight="500"
                  textAlign="center"
                >
                  Thank you for your interest!
                </Text>
                <Text
                  fontSize="sm"
                  color={alternativeLight}
                  m="1rem 0 0"
                  textAlign="center"
                >
                  We will get back to you soon. In the meantime, feel free to
                  download our brochure.
                </Text>

                {/* <Flex m="1rem 0 0">
                  <Button
                    size="lg"
                    h="3.5rem"
                    fontSize="md"
                    fontWeight="500"
                    borderRadius="10rem"
                    variant="outline"
                    border={`1px solid ${primaryDark}`}
                    color={primaryLight}
                    px="3rem"
                    bg={primaryDark}
                    _hover={{
                      border: `1px solid ${primaryLight}`,
                      bg: primaryLight,
                      color: primaryDark,
                    }}
                    onClick={() => {
                      window.open(
                        "https://ricebali.com/ricebali-presentation-2024.pdf",
                        "_blank"
                      );
                    }}
                  >
                    Download PDF
                  </Button>
                </Flex> */}

                <Flex
                  m="0 auto"
                  w="100%"
                  align="center"
                  justify="center"
                  p="0rem 0 3rem"
                >
                  <Button
                    m="3rem 0 0"
                    size="lg"
                    h="2.5rem"
                    fontSize="md"
                    fontWeight="500"
                    borderRadius="10rem"
                    variant="outline"
                    border={`1px solid ${primaryLight}`}
                    color={primaryLight}
                    px="2rem"
                    _hover={{
                      bg: primaryLight,
                      color: primaryDark,
                    }}
                    onClick={closeHandler}
                  >
                    Ok
                  </Button>
                </Flex>
              </Flex>
            )}

            {!stepSucess && (
              <>
                <Flex
                  align="center"
                  justify="center"
                  w="100%"
                  direction="column"
                  p="3rem 1rem"
                >
                  <Image src={riceone} alt="RiceOne" h="1.75rem" />
                  <Text
                    fontSize="sm"
                    color={primaryLight}
                    m="1.5rem 0 0"
                    textAlign="center"
                  >
                    Rice Bali Villas waiting for you. take a look.
                  </Text>

                  <Flex
                    w="100%"
                    align="center"
                    justify="center"
                    m={["1.5rem 0 0", "1.5rem 0 0", "1.5rem 0 0"]}
                  >
                    <form
                      ref={form}
                      onSubmit={handleSubmit}
                      style={{
                        width: "100%",
                      }}
                    >
                      <Flex
                        w="100%"
                        maxW={["100%", "100%", "1000px"]}
                        gap="1rem"
                        direction={["column", "column", "row"]}
                      >
                        <FormControlInput
                          isRequired
                          type="text"
                          label="Name"
                          value={name}
                          name="name"
                          onChange={(e) => setName(e.target.value)}
                          // errorMessage="Name is required"
                          w="100%"
                          placeholder="Your name"
                        />
                        <FormControlInput
                          isRequired
                          type="email"
                          label="Email"
                          value={email}
                          name="email"
                          onChange={(e) => setEmail(e.target.value)}
                          // errorMessage="Email is required"
                          w="100%"
                          placeholder="Your email address"
                        />
                        <FormControlInput
                          type="text"
                          label="Phone"
                          value={phone}
                          name="phone"
                          onChange={(e) => setPhone(e.target.value)}
                          // errorMessage="Name is required"
                          w="100%"
                          placeholder="Your phone number"
                          m="0 0 1rem"
                        />
                      </Flex>

                      <Flex
                        w="100%"
                        maxW={["100%", "100%", "1000px"]}
                        gap="1rem"
                        position="relative"
                        zIndex={900}
                        direction={["column", "column", "row"]}
                      >
                        <FormControlSelect
                          label="Bedrooms"
                          value={bedrooms}
                          name="bedrooms"
                          onChange={(selectedOption) => {
                            setBedrooms(selectedOption.value);
                          }}
                          w="100%"
                          placeholder="Bedrooms"
                          options={bedroomsOptions}
                        />
                        <FormControlSelect
                          label="Bathrooms"
                          value={bathrooms}
                          name="bathrooms"
                          onChange={(selectedOption) => {
                            setBathrooms(selectedOption.value);
                          }}
                          // errorMessage="Bedrooms is required"
                          w="100%"
                          placeholder="Bathrooms"
                          options={bathroomsOptions}
                        />
                        <FormControlInput
                          type="number"
                          label="ROI %"
                          value={roiGoal}
                          name="roiGoal"
                          onChange={(e) => setRoiGoal(e.target.value)}
                          // errorMessage="Email is required"
                          w="100%"
                          placeholder="Enter expected ROI"
                        />
                        {/* <FormControlSelect
                      label="ROI"
                      value={roiGoal}
                      onChange={(e) => setRoiGoal(e.target.value)}
                      // errorMessage="Bathrooms is required"
                      w="100%"
                      placeholder="ROI Goal"
                      options={roiOptions}
                    /> */}
                      </Flex>
                    </form>
                  </Flex>
                </Flex>
                <Flex
                  m="0 auto"
                  w="100%"
                  align="center"
                  justify="center"
                  p="0rem 0 3rem"
                >
                  <Button
                    size="lg"
                    h="3rem"
                    fontSize="md"
                    fontWeight="500"
                    borderRadius="10rem"
                    variant="outline"
                    border={`1px solid ${primaryLight}`}
                    color={primaryLight}
                    px="3rem"
                    _hover={{
                      bg: primaryLight,
                      color: primaryDark,
                    }}
                    onClick={handleSubmit}
                    isDisabled={isSendDisabled()}
                  >
                    {sending ? (
                      <Spinner size="sm" color={primaryDark} />
                    ) : (
                      "Send"
                    )}
                  </Button>
                </Flex>
              </>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
      {/* // MODAL END */}
    </>
  );
};

export default ContactModal;
