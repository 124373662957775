import React from "react";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Flex,
  Text,
} from "@chakra-ui/react";

import Select from "react-select";
import {
  primaryMid,
  primaryLight,
  primaryDark,
  borderDark,
  alternativeLight,
  secondaryDark,
  secondaryMid,
} from "../../utils/colors";

const FormControlSelect = ({
  label,
  value,
  onChange,
  isRequired,
  isTouched,
  isError,
  errorMessage,
  w,
  placeholder,
  options,
  name,
  m,
}) => {
  const formatOptionLabel = ({ label, value }, { context }) => {
    return (
      <Flex align="center" justify="space-between">
        <Text fontSize="sm">{label}</Text>
        {/* {context === "menu" ? <Text color={primaryMid}>{value}</Text> : null} */}
      </Flex>
    );
  };

  return (
    <FormControl
      m={m}
      isRequired={isRequired}
      isInvalid={isTouched && isError}
      w={w}
    >
      <FormLabel fontSize="sm" position="relative" color={alternativeLight}>
        {label}
        {isError && isTouched && (
          <div>
            <FormErrorMessage fontSize="xs">{errorMessage}</FormErrorMessage>
          </div>
        )}
      </FormLabel>

      <Select
        isSearchable
        value={options.find((option) => option.value === value)}
        onChange={onChange}
        options={options}
        placeholder={placeholder}
        zIndex={999}
        name={name}
        bg="transparent"
        formatOptionLabel={formatOptionLabel} // Custom label formatting
        styles={{
          menu: (base) => ({
            ...base,
            zIndex: 9000,
            backgroundColor: "transparent",
            padding: 0,
            borderRadius: "1rem",
          }),
          menuList: (provided) => ({
            ...provided,
            padding: 0,
            zIndex: 9000,

            borderRadius: "1rem",
            bg: "green",
          }),
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: isError
              ? "red"
              : state.isFocused
              ? primaryMid
              : primaryMid,
            borderRadius: "1rem",
            backgroundColor: "transparent",
            border: `1px solid ${borderDark}`,
            fontSize: "0.938rem",
            fontWeight: "500",
            height: "3rem",
            padding: "0 0.25rem",
            boxShadow: "none",
            color: primaryLight,

            ":focus-visible": {
              borderColor: primaryDark,
              boxShadow: "none",
              outline: "none",
            },
            ":hover": {
              borderColor: primaryMid,
              boxShadow: "none",
            },
            ":focus": {
              borderColor: primaryDark,
              boxShadow: "none",
              outline: "none",
            },
          }),
          option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? primaryLight : secondaryDark, // Color of text in options
            backgroundColor: state.isSelected ? secondaryMid : primaryMid, // Background color of selected option
            ":hover": {
              backgroundColor: secondaryDark, // Background color when hovering
              color: "white",
            },
          }),
          singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = "opacity 300ms";
            const color = primaryLight;

            return { ...provided, opacity, transition, color };
          },
        }}
      />
    </FormControl>
  );
};

export default FormControlSelect;
