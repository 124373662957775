import React from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Tooltip,
} from "@chakra-ui/react";
import {
  primaryLight,
  primaryMid,
  borderDark,
  alternativeLight,
} from "../../utils/colors";

const FormControlInput = ({
  type,
  isRequired,
  label,
  value,
  placeholder,
  onChange,
  isTouched,
  isError,
  w,
  errorMessage,
  isSmall,
  isDisabled,
  onFocus,
  onBlur,
  rightElement,
  noDisabledOpacity,
  isTooltip,
  tooltipLabel,
  m,
  name,
}) => {
  return (
    <FormControl
      m={m}
      isRequired={isRequired}
      isInvalid={isTouched && isError}
      w={w}
      isDisabled={isDisabled}
      position="relative"
    >
      <FormLabel
        fontSize={isSmall ? "xs" : "sm"}
        position="relative"
        color={alternativeLight}
        _disabled={{
          opacity: noDisabledOpacity ? 1 : 0.5,
        }}
      >
        {label}
        {isError && isTouched && (
          <Box position="absolute" top={-1} right={0} zIndex={2}>
            <FormErrorMessage fontSize="xs">{errorMessage}</FormErrorMessage>
          </Box>
        )}
      </FormLabel>
      <Tooltip
        label={tooltipLabel}
        placement="top"
        p="0.5rem 1rem"
        borderRadius="1rem"
        isDisabled={!isTooltip}
      >
        <Input
          type={type}
          bg="transparent"
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          size={isSmall ? "md" : "lg"}
          fontSize={isSmall ? "sm" : "0.938rem"}
          fontWeight="500"
          _placeholder={{ opacity: 1, color: "#807F7F" }}
          color={primaryLight}
          border={`1px solid ${borderDark}`}
          borderRadius="0.75rem"
          boxShadow="none !important"
          _autofill={{
            border: `1px solid ${borderDark}`,
            textFillColor: primaryLight,
            boxShadow: "0 0 0px 1000px #232323 inset",
            transition: "background-color 5000s ease-in-out 0s",
          }}
          _focus={{
            border:
              isError && isTouched
                ? `1px solid red`
                : `1px solid ${primaryLight}`,
            boxShadow: "none",
          }}
          _hover={{
            border: `1px solid ${primaryMid}`,
          }}
          _disabled={{
            opacity: noDisabledOpacity ? 0.5 : 0.5,
            cursor: "not-allowed",
          }}
        />
      </Tooltip>
      {rightElement}
    </FormControl>
  );
};

export default FormControlInput;
